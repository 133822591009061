exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cgu-js": () => import("./../../../src/pages/cgu.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-curcumagasin-commande-js": () => import("./../../../src/pages/curcumagasin/commande.js" /* webpackChunkName: "component---src-pages-curcumagasin-commande-js" */),
  "component---src-pages-curcumagasin-formules-js": () => import("./../../../src/pages/curcumagasin/formules.js" /* webpackChunkName: "component---src-pages-curcumagasin-formules-js" */),
  "component---src-pages-curcumagasin-menu-desserts-js": () => import("./../../../src/pages/curcumagasin/menu/desserts.js" /* webpackChunkName: "component---src-pages-curcumagasin-menu-desserts-js" */),
  "component---src-pages-curcumagasin-menu-plats-js": () => import("./../../../src/pages/curcumagasin/menu/plats.js" /* webpackChunkName: "component---src-pages-curcumagasin-menu-plats-js" */),
  "component---src-pages-curcumagasin-menu-soupes-js": () => import("./../../../src/pages/curcumagasin/menu/soupes.js" /* webpackChunkName: "component---src-pages-curcumagasin-menu-soupes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nos-services-js": () => import("./../../../src/pages/nos-services.js" /* webpackChunkName: "component---src-pages-nos-services-js" */),
  "component---src-pages-qui-sommes-nous-js": () => import("./../../../src/pages/qui-sommes-nous.js" /* webpackChunkName: "component---src-pages-qui-sommes-nous-js" */),
  "component---src-pages-succes-commandev-2-js": () => import("./../../../src/pages/succes/commandev2.js" /* webpackChunkName: "component---src-pages-succes-commandev-2-js" */)
}

